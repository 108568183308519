// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD0Md1heB1_pPXLOmeo65CVQdJYgW6uTak",
    authDomain: "bg-event-scheduler.firebaseapp.com",
    databaseURL: "https://bg-event-scheduler-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "bg-event-scheduler",
    storageBucket: "bg-event-scheduler.appspot.com",
    messagingSenderId: "142464913406",
    appId: "1:142464913406:web:bf06401ae455e15741baaf",
    measurementId: "G-3ND3KKG0W1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { app, db, auth };