import logo from './logo.svg';
import './App.css';
import { db } from './Firebase.js';
import { collection, query, where, onSnapshot, Timestamp } from "firebase/firestore";
import React, { useImperativeHandle, useState } from 'react';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      events: []
    };
  }

  componentDidMount() {
    const q = query(collection(db, "events"), where("name", '!=', ""));

    const unsub = onSnapshot(q, (querySnapshot) => {
      var eventList = [];
      querySnapshot.forEach((doc) => {
        var time = null;
        try {
          time = new Date(doc.data().depart);
        }catch{
          time = "";
        }
        var theTime = time != null ? time.toLocaleString('en-GB') : "";
        /*var theTime = time.toLocaleString('en-GB', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        });*/
        eventList.push({
          name: doc.data().name,
          depart: theTime,
          vehicles: doc.data().vehicles,
          kit: doc.data().kit,
          staff: doc.data().staff
        });
        console.log(doc.data().vehicles);
      });
      console.log("Current events on file: " + eventList.join(", "));
      this.setState({ events: eventList });
    });
  }

  render() {

    return (
      <div className="App">
        <header className="top-bar">
          <h2>Today's Event Schedule</h2>
          <img className="bg-logo" src="https://basegreenacademy.co.uk/wp-content/uploads/2022/04/white-logo-to-use-on-dark-backgrounds-footer-2048x451.png" />
        </header>
        <div className="event-info">
          {this.state.events.map(({ name, depart, vehicles, kit, staff }) => (
            <div className="event-block col-md-6">
              <div className="eb-header">
                <h2>{name ?? ""}</h2>
                <h2>Set off: {depart ?? ""}</h2>
              </div>
              <hr />
              <div className="eb-vehicles">
                {(vehicles != null) ? vehicles.map((message, i) => {
                  return (<span className="eb-reg">{message}</span>);
                }) : ""}
              </div>
              <hr />
              <div className="container">
                <div className="eb-body row">
                  <div className="eb-kitlist col-md-6">
                    <ul>
                      {(kit != null) ? kit.map((message, i) => {
                        return (<li>{message}</li>);
                      }) : ""}
                    </ul>
                  </div>
                  <div className="eb-stafflist col-md-6">
                    <ul>
                      {staff != null ? staff.map((message, i) => {
                        var name = message.split('-')[0];
                        var role = message.split('-')[1];
                        return (<li>{name} <span className="badge badge-secondary">{role}</span></li>);
                      }) : ""}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default App;
