import logo from './logo.svg';
import './App.css';
import { db, auth } from './Firebase.js';
import { collection, query, where, onSnapshot, Timestamp, doc, setDoc, deleteDoc } from "firebase/firestore";
import React, { useImperativeHandle, useState } from 'react';
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import $ from "jquery";
import Modal from "react-modal";


class Admin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            modalIsOpen: false,
            id: "",
            name: "",
            depart: "",
            vehicles: [],
            kit: [],
            staff: []
        };
    }



    openModal() {
        this.setState({ modalIsOpen: true })
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            id: "",
            name: "",
            depart: "",
            kit: [],
            staff: [],
            vehicles: []
        });
    }



    componentDidMount() {
        const q = query(collection(db, "events"), where("name", '!=', ""));

        const unsub = onSnapshot(q, (querySnapshot) => {
            var eventList = [];
            querySnapshot.forEach((doc) => {
                var time = doc.data().depart != null ? new Date(doc.data().depart) : "";
                var theTime = time != null ? time.toLocaleString('en-GB') : "";
                //var theTime = time ?? "";
                eventList.push({
                    id: doc.id,
                    name: doc.data().name,
                    depart: theTime,
                    departRaw: doc.data().depart,
                    vehicles: doc.data().vehicles,
                    kit: doc.data().kit,
                    staff: doc.data().staff
                });
                console.log(doc.data().vehicles);
            });
            console.log("Current events on file: " + eventList.join(", "));
            this.setState({ events: eventList });
        });
    }

    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    render() {

        const customStyles = {
            content: {
                backgroundColor: "#000",
                top: '50%',
                left: '50%',
                right: '25%',
                bottom: '10%%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
            },
        };

        return (
            <div className="App">
                <header className="top-bar">
                    <h2>Event Schedule Administration</h2>
                    <img className="bg-logo" src="https://basegreenacademy.co.uk/wp-content/uploads/2022/04/white-logo-to-use-on-dark-backgrounds-footer-2048x451.png" />
                </header>
                <div class="row" style={{ maxWidth: "80%", margin: "auto" }}>

                    {auth.currentUser == null ? (
                        <div><h2>Please log in</h2>
                            <form>
                                <div class="form-group">
                                    <label for="emailInput1">Email address</label>
                                    <input type="email" class="form-control" id="emailInput" aria-describedby="emailHelp" placeholder="Enter email" />
                                </div>
                                <div class="form-group">
                                    <label for="passwordInput1">Password</label>
                                    <input type="password" class="form-control" id="passwordInput" placeholder="Password" />
                                </div>
                                <button type="submit" class="btn btn-primary" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState(() => signInWithEmailAndPassword(auth, $("#emailInput").val(), $("#passwordInput").val()).then(() => {
                                        window.location.reload();
                                    }));
                                }}>Log In</button>
                            </form>
                        </div>
                    ) :
                        (
                            <div>
                                <div style={{ display: "inline-flex" }}>
                                    <button type="logout" class="btn btn-primary" onClick={(e) => {
                                        e.preventDefault();
                                        signOut(auth).then(() => {
                                            this.setState(() => { window.location.reload() });
                                        });
                                    }}>Log Out</button>
                                    <p>{auth.currentUser.email}</p>
                                </div><br />
                                <hr />
                                <button type="button" class="btn btn-primary" style={{ marginBottom: "10px" }} onClick={async () => {
                                    this.openModal();
                                }}>Add new event</button>
                                {this.state.events.map(({ id, name, depart, departRaw, vehicles, kit, staff }) => (
                                    <div className="event-block-admin">
                                        <div className="eba-header">
                                            <h2>{name}</h2>
                                            <h2>{depart}</h2>
                                            <button type="button" id={"edit" + id} class="btn btn-info" style={{ marginRight: "20px" }} onClick={() => {
                                                this.setState({
                                                    id: id,
                                                    name: name,
                                                    depart: depart,
                                                    departRaw: departRaw,
                                                    vehicles: vehicles,
                                                    kit: kit,
                                                    staff: staff
                                                })
                                                this.openModal();
                                            }}>Edit event</button>
                                            <button type="button" id={"del" + id} class="btn btn-danger" onClick={async () => {
                                                await deleteDoc(doc(db, "events", id));
                                            }}>Delete event</button>
                                        </div></div>))}
                                <Modal
                                    isOpen={this.state.modalIsOpen}
                                    //onAfterOpen={afterOpenModal}
                                    onRequestClose={this.closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >
                                    <div style={{ backgroundColor: "#000" }}>
                                        <button type="button" class="btn-close" aria-label="Close" style={{ color: "#000", float: "right" }} onClick={() => this.closeModal()}>&times;</button>
                                        <h2>Add an event record</h2>

                                        <form style={{ color: "#000 !important" }}>
                                            <div class="form-group">
                                                <label for="eventNameInput">Event Name</label>
                                                <input type="text" class="form-control" id="eventName" placeholder="AJ Bell Stadium" defaultValue={this.state.name} />
                                            </div>
                                            {/*<div class="form-group">
                                                <label for="eventLocationInput">Event Location</label>
                                                <input type="text" class="form-control" id="eventLocation" placeholder="Stadium Way" value={this.state.location} />
                                        </div>*/}
                                            <div class="form-group">
                                                <label for="eventTime">Time of event</label>
                                                <input type="datetime-local" disabled={this.state.depart != ""} style={{ display: this.state.depart != "" ? "none" : "block" }} class="form-control" id="eventTime" name="eventTime" />
                                                <p>{this.state.depart}</p>
                                            </div>
                                            <div class="form-group">
                                                <label for="kitList">Kit List</label>
                                                <textarea class="form-control" id="kitList" name="kitList" rows="5" defaultValue={this.state.kit.join("\n")} />
                                            </div>
                                            <div class="form-group">
                                                <label for="staffList">Staff List</label>
                                                <textarea class="form-control" id="staffList" placeholder="NAME-ROLE" name="staffList" rows="5" defaultValue={this.state.staff.join("\n")} />
                                            </div>
                                            <div class="form-group">
                                                <label for="vehList">Vehicle List</label>
                                                <textarea class="form-control" placeholder="EU13 DXL" id="vehList" name="vehList" rows="2" defaultValue={this.state.vehicles.join("\n")} />
                                            </div>
                                            <button type="button" class="btn btn-primary" onClick={async () => {
                                                var kitlist = $("#kitList").val().split("\n");
                                                var stafflist = $("#staffList").val().split("\n");
                                                var vehiclelist = $("#vehList").val().split("\n");
                                                //var ts = (this.state.depart == "") ? new Date($("#eventTime").val()) : this.state.depart;
                                                var ts = (this.state.depart == "") ? $("#eventTime").val() : this.state.departRaw;
                                                //var ts = (this.state.depart == "") ? Timestamp.fromDate(new Date($("#eventTime").val())) : this.state.depart;
                                                await setDoc(doc(db, "events", this.state.id == "" ? this.makeid(16) : this.state.id), {
                                                    name: $("#eventName").val(),
                                                    //location: $("#eventLocation").val(),
                                                    depart: ts,
                                                    kit: kitlist,
                                                    staff: stafflist,
                                                    vehicles: vehiclelist
                                                });
                                                this.closeModal();
                                                /*db.collection("events").doc(this.makeid(16).set({
                                                    name: $("#eventName").val(),
                                                    location: $("#eventLocation").val(),
                                                    depart: $("#eventTime").val(),
                                                    kit: kitlist,
                                                    staff: stafflist,
                                                    vehicles: vehiclelist
                                                })).then(() => {
                                                    this.closeModal();
                                                }).catch((error) => {
                                                    console.error("Error writing: " + error);
                                                })*/
                                            }}>Submit</button>
                                        </form>
                                    </div>
                                </Modal>

                            </div>)
                    }
                </div>
            </div >
        );
    }
}



export default Admin;
